import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import moment from 'moment'
moment.locale('ja')

import ModalLayout from 'components/commons/layouts/ModalLayout'
import ModalCloseButton from 'components/buttons/ModalCloseButtonComponent'
import LuggageButtonComponent from 'components/buttons/LuggageButtonComponent'
import DateRangeCalendarComponent from 'components/calendars/DateRangeCalendarComponent'

import hourList from 'data/hour_list.json'

import styles from './SearchDateLuggageModal.module.scss'

interface Props {
  locale: string
  isShow: boolean
  onPressCloseModalRequest: () => void
  onPressApplyButton: (selectedDate, selectedDateTime, bagSizeStepper, suitcaseSizeStepper) => void

  selectedDate: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }
  selectedDateTime: {
    startDateTime: string
    endDateTime: string
  }
  setSelectedDate: any

  defaultBagSizeStepper: number
  defaultSuitcaseSizeStepper: number

  isVisibleFilterButton?: boolean
  onPressSearchFilterModal?: () => void
}

const SearchDateLuggageModal: React.FC<Props> = ({
  locale,
  isShow,
  onPressCloseModalRequest,
  onPressApplyButton,
  selectedDate,
  selectedDateTime,
  defaultBagSizeStepper,
  defaultSuitcaseSizeStepper,
  isVisibleFilterButton,
  onPressSearchFilterModal,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [isShowDateCalendar, setIsShowDateCalendar] = useState<boolean>(false)
  const [checkedDate, setCheckedDate] = useState<{
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }>({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
  })
  const [dateTime, setDateTime] = useState<{ startDateTime: string; endDateTime: string }>(
    selectedDateTime
  )

  const [bagSizeStepper, setBagSizeStepper] = useState<number>(defaultBagSizeStepper)
  const [suitcaseSizeStepper, setSuitcaseSizeStepper] = useState<number>(defaultSuitcaseSizeStepper)

  return (
    <ModalLayout
      openModal={isShow}
      onPressCloseModalRequest={onPressCloseModalRequest}
      modalStyle={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContainer}>
        <ModalCloseButton
          onPressCloseModalRequest={onPressCloseModalRequest}
          closeButtonStyle={styles.modalCloseButton}
        />
        <div className={styles.modalTitle}>{t('SPACES.SEARCH.CHECK_DATETIME_LUGGAGE')}</div>
        <div className={styles.modalContent}>
          <div className={styles.dateBox}>
            <label className={styles.dateLabel}>{t('SPACES.SEARCH.CHECK_DATE')}</label>
            <div className={styles.dateInputBox}>
              {checkedDate.startDate === null && checkedDate.endDate === null ? (
                <span
                  className={`${styles.dateInputBoxPlaceholder} ${styles.dateInputBoxPlaceholderDate}`}
                  onClick={() => {
                    setIsShowDateCalendar(!isShowDateCalendar)
                  }}
                >
                  {t('SPACES.SEARCH.ENTER_DATE')}
                </span>
              ) : (
                <div
                  className={`${styles.dateInputBoxResult} ${styles.dateInputBoxResultDate}`}
                  onClick={() => {
                    setIsShowDateCalendar(!isShowDateCalendar)
                  }}
                >
                  <span className={styles.dateInputBoxResultDateText}>
                    {checkedDate.startDate?.format('YYYY/MM/DD') ?? ''} {dateTime.startDateTime}
                  </span>
                  <span className={styles.dateInputBoxResultDateText}>-</span>
                  <span className={styles.dateInputBoxResultDateText}>
                    {checkedDate.endDate?.format('YYYY/MM/DD') ?? ''} {dateTime.endDateTime}
                  </span>
                </div>
              )}
              <div className={styles.dateInputBoxDateCalendar}>
                <DateRangeCalendarComponent
                  locale={locale}
                  isShow={isShowDateCalendar}
                  isShowTimePicker={false}
                  onPressCloseModalRequest={() => {
                    setIsShowDateCalendar(false)
                  }}
                  selectedDate={checkedDate}
                  setSelectedDate={setCheckedDate}
                />
              </div>
            </div>
          </div>

          <div className={styles.dateTimeContents}>
            <div className={styles.dateTimeBox}>
              <label className={styles.dateLabel}>{t('SPACES.SEARCH.CHECK_DATETIME')}</label>
              <select
                className={styles.dateTimeSelect}
                value={dateTime.startDateTime}
                onChange={(e) => {
                  dateTime.startDateTime = e.target.value
                  setDateTime({
                    startDateTime: dateTime.startDateTime,
                    endDateTime: dateTime.endDateTime,
                  })
                }}
              >
                {hourList.map((item, index) => (
                  <option key={index} value={item.hour}>
                    {item.hour}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.dateTimeBox}>
              <label className={styles.dateLabel}>{t('SPACES.SEARCH.CHECK_OUT_DATETIME')}</label>
              <select
                className={styles.dateTimeSelect}
                value={dateTime.endDateTime}
                onChange={(e) => {
                  dateTime.endDateTime = e.target.value
                  setDateTime({
                    startDateTime: dateTime.startDateTime,
                    endDateTime: dateTime.endDateTime,
                  })
                }}
              >
                {hourList.map((item, index) => (
                  <option key={index} value={item.hour}>
                    {item.hour}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p className={styles.dateTimeDescription}>
            {t('SPACES.SEARCH.SELECT_CHECK_IN_ABOUT_DATETIME')}
          </p>
          <div className={styles.luggageContents}>
            <div className={styles.luggage}>
              <img
                className={styles.luggageIcon}
                src="/bag_icon_gray.svg"
                alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
              />
              <strong className={styles.luggageLabel}>{t('COMMON.BAG_SIZE')}</strong>
              <span className={styles.luggageDescription}>
                {t('SPACES.SEARCH.LUGGAGE.BAG_SIZE_DESCRIPTION_SHORT')}
              </span>
              <div className={styles.luggageStepper}>
                <LuggageButtonComponent
                  sizeStepper={bagSizeStepper}
                  setSizeStepper={setBagSizeStepper}
                  buttonStyle={styles.luggageStepperButton}
                />
              </div>
            </div>
            <div className={styles.luggage}>
              <img
                className={styles.luggageIcon}
                src="/suitcase_icon_gray_no_margin.svg"
                alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
              />
              <strong className={styles.luggageLabel}>{t('COMMON.SUITECASE_SIZE')}</strong>
              <span className={styles.luggageDescription}>
                {t('COMMON.SUITECASE_SIZE')}
                {t('SPACES.SEARCH.LUGGAGE.SUITCASE_SIZE_DESCRIPTION_SHORT')}
              </span>
              <div className={styles.luggageStepper}>
                <LuggageButtonComponent
                  sizeStepper={suitcaseSizeStepper}
                  setSizeStepper={setSuitcaseSizeStepper}
                  buttonStyle={styles.luggageStepperButton}
                />
              </div>
            </div>
          </div>
          {isVisibleFilterButton && (
            <div className={styles.filterContent}>
              <button
                className={styles.filterButton}
                type="button"
                onClick={onPressSearchFilterModal}
              >
                <span className={styles.filterButtonText}>{t('COMMON.DO_FILTER')}</span>
              </button>
            </div>
          )}
        </div>
        <button
          className={styles.modalApplyButton}
          onClick={() => {
            onPressApplyButton(checkedDate, dateTime, bagSizeStepper, suitcaseSizeStepper)
          }}
        >
          {t('COMMON.DO_APPLY')}
        </button>
      </div>
    </ModalLayout>
  )
}

export default SearchDateLuggageModal
