import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import DateRangeCalendarComponent from '../../calendars/DateRangeCalendarComponent'

import SearchAreaModal from 'components/domains/spaces/SearchAreaModal'
import SearchDateLuggageModal from 'components/domains/spaces/SearchDateLuggageModal'
import LuggageButtonComponent from '../../buttons/LuggageButtonComponent'

import { Coordinate } from 'state/ducks/space/models'

import checkBreakPoint from 'lib/checkBreakpoint'
import moment from 'moment'
import styles from './SearchForm.module.scss'

import hourList from 'data/hour_list.json'

import { usePlaceAutoCompletion } from 'hooks/locations/usePlaceAutoCompletion'

interface Props {
  page?: string
  onPressSearchSpace?: (latLng, selectedDate, selectedDateTime, bagSize, suitcaseSize) => void
  setSearchParams?: ({ lat, lon }) => void
  onPressNavigationSearchSpacePage?: (
    value,
    selectedDate,
    checkInDateTime,
    checkOutDateTime,
    bagSize,
    lat,
    lon,
    suitcaseSize,
    isLocation
  ) => void
  isVisibleFilterButton?: boolean
  onPressSearchFilterModal?: () => void
  locale?: string
}

const SearchForm: React.FC<Props> = ({
  page,
  onPressSearchSpace,
  setSearchParams,
  onPressNavigationSearchSpacePage,
  isVisibleFilterButton,
  onPressSearchFilterModal,
  locale,
}: Props): JSX.Element => {
  const { query } = useRouter()
  const { t } = useTranslation()
  const { isDesktop } = checkBreakPoint()

  const [isShowDateCalendar, setIsShowDateCalendar] = useState<boolean>(false)
  const [isShowAreaModal, setIsShowAreaModal] = useState<boolean>(false)
  const [isShowDateLuggageModal, setIsShowDateLuggageModal] = useState<boolean>(false)

  const luggageRef = useRef(null)
  const [openLuggage, setOpenLuggage] = useState<boolean>(false)
  const [bagSizeStepper, setBagSizeStepper] = useState<number>(Number(query.bagSize) || 0)
  const [suitcaseSizeStepper, setSuitcaseSizeStepper] = useState<number>(
    Number(query.suitcaseSize) || 0
  )
  const [latLng, setLatLng] = useState<Coordinate>({ lat: 0, lon: 0 })
  const [selectedDate, setSelectedDate] = useState<{
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }>({
    startDate: query.startDate ? moment(query.startDate) : null,
    endDate: query.startDate ? moment(query.endDate) : null,
  })

  const { places, setPlaces, searchText, setSearchText, getGeocode } = usePlaceAutoCompletion()

  const [inputText, setInputText] = useState<string>('')

  const getDefaultDateTime = (type: string) => {
    let dateTime: string = ''

    if (type === 'start') {
      if (query.startDateTimeHour && query.startDateTimeMin) {
        dateTime = `${query.startDateTimeHour}:${query.startDateTimeMin}`
      } else if (query.startDateTimeHour) {
        dateTime = `${query.startDateTimeHour}:00`
      } else {
        //10:00
        dateTime = hourList[2].hour
      }
    } else {
      if (query.endDateTimeHour && query.endDateTimeMin) {
        dateTime = `${query.endDateTimeHour}:${query.endDateTimeMin}`
      } else if (query.endDateTimeHour) {
        dateTime = `${query.endDateTimeHour}:00`
      } else {
        //19:00
        dateTime = hourList[20].hour
      }
    }
    return dateTime
  }

  const [selectedDateTime, setSelectedDateTime] = useState<{
    startDateTime: string
    endDateTime: string
  }>({
    startDateTime: getDefaultDateTime('start'),
    endDateTime: getDefaultDateTime('end'),
  })

  const [inputFocusType, setInputFocusType] = useState<string>('')
  const resetInputFocus = () => {
    setInputFocusType('')
  }

  //検索ボタンの有効フラグ
  const isSearchButtonActive =
    inputText === '' &&
    (selectedDate.startDate === null || selectedDate.endDate === null) &&
    bagSizeStepper === 0 &&
    suitcaseSizeStepper === 0
      ? true
      : false

  //入力ボックスの横並び
  const isHorizontalInputBox = page === 'locations' ? true : false

  useEffect(() => {
    document.addEventListener('click', _onPressLuggageContainer)

    return () => {
      document.removeEventListener('click', _onPressLuggageContainer)
    }
  }, [])

  useEffect(() => {
    if (query.name) {
      setInputText(String(query.name))
      // TODO
      // getGeocode({ address: String(query.name) })
      //   .then((results) => getLatLng(results[0]))
      //   .then(({ lat, lng }) => {
      //     setSearchParams({ lat: lat, lon: lng })
      //   })
      //   .catch((error) => {
      //     // do nothing
      //   })
    }
  }, [query.name])

  useEffect(() => {
    // Local stageに入れる
    localStorage.setItem(
      'search_params',
      JSON.stringify({
        selectedDate: selectedDate,
        selectedDateTime: selectedDateTime,
        bagSize: bagSizeStepper,
        suitcaseSize: suitcaseSizeStepper,
      })
    )
  }, [selectedDate, selectedDateTime, bagSizeStepper, suitcaseSizeStepper])

  const _handleSelect = useCallback(
    async (place) => {
      setInputText(place.description)
      setSearchText('')
      setPlaces([])

      const geocode = await getGeocode(place.place_id)

      if (geocode) {
        const { lat, lng } = geocode
        setLatLng({ lat: lat, lon: lng })
      }
    },
    [getGeocode, setPlaces, setSearchText]
  )

  const _onPressLuggageContainer = useCallback((event): void => {
    if (luggageRef && luggageRef.current.contains(event.target)) {
      setOpenLuggage(true)
    } else {
      setOpenLuggage(false)
      resetInputFocus()
    }
  }, [])

  /**
   * 言語を基にフォーマットを返す
   * @param date moment.Moment 日付データ
   * @param locale string 言語キー
   * @returns
   */
  const formattedDateTime = (date: moment.Moment = null, locale: string): string => {
    if (date === null) {
      return ''
    }

    return locale === 'ja' ? date.format('M月D日') : date.format('M/D')
  }

  const applySearchOnParamsDateLuggageModal = (
    checkedDate,
    dateTime,
    bagSizeStepperCount,
    suitcaseSizeStepperCount
  ): void => {
    setSelectedDate({
      startDate: checkedDate.startDate,
      endDate: checkedDate.endDate,
    })

    setSelectedDateTime({
      startDateTime: dateTime.startDateTime,
      endDateTime: dateTime.endDateTime,
    })

    setBagSizeStepper(bagSizeStepperCount)
    setSuitcaseSizeStepper(suitcaseSizeStepperCount)
  }

  return (
    <>
      <div className={`${styles.search} ${isHorizontalInputBox ? styles.searchHorizontal : ''}`}>
        <div className={styles.searchForm}>
          <div
            className={`${styles.searchInputBox} ${styles.searchInputBoxArea} ${
              inputFocusType === 'area' ? styles.searchInputBoxFocused : ''
            }`}
          >
            <strong className={styles.searchInputBoxLabel}>
              <span className={styles.searchInputBoxLabelText}>
                {t('SPACES.SEARCH.AREA.PLACEHOLDER')}
              </span>
              <img
                className={styles.searchInputBoxLabelIcon}
                src="map_pin_icon_navy_dark.svg"
                alt={t('META.NOT_MATCHING_SPACE_ICON')}
              />
            </strong>
            {isDesktop ? (
              <input
                type="text"
                className={styles.searchInputBoxInput}
                placeholder={t('SPACES.SEARCH.AREA_MODAL.ENTER_AREA_NAME')}
                value={inputText}
                onChange={(event) => {
                  const text = event.target.value
                  setSearchText(text)
                  setInputText(text)
                }}
                onFocus={() => setInputFocusType('area')}
              />
            ) : (
              <span
                className={`${
                  inputText === ''
                    ? styles.searchInputBoxPlaceholder
                    : styles.searchInputBoxResultAreaText
                }`}
                onClick={() => {
                  setIsShowAreaModal(!isShowAreaModal)
                }}
              >
                {inputText === '' ? t('SPACES.SEARCH.AREA_MODAL.ENTER_AREA_NAME') : inputText}
              </span>
            )}
            {!!searchText && !!places.length && (
              <ul className={styles.suggest}>
                {places.map((suggestion) => (
                  <li
                    key={suggestion.place_id}
                    onClick={() => _handleSelect(suggestion)}
                    className={styles.suggestText}
                  >
                    {suggestion.description}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div
            className={`${styles.searchInputBox} ${styles.searchInputBoxDate} ${
              inputFocusType === 'date' ? styles.searchInputBoxFocused : ''
            }`}
          >
            <strong className={styles.searchInputBoxLabel}>
              <span className={styles.searchInputBoxLabelText}>
                {t('SPACES.SEARCH.CHECK_DATETIME')}
              </span>
            </strong>
            {selectedDate.startDate === null && selectedDate.endDate === null ? (
              <span
                className={styles.searchInputBoxPlaceholder}
                onClick={() => {
                  setInputFocusType('date')
                  setIsShowDateCalendar(!isShowDateCalendar)
                }}
              >
                {t('SPACES.SEARCH.ENTER_DATE')}
              </span>
            ) : (
              <div
                className={`${styles.searchInputBoxResult} ${styles.searchInputBoxResultDate}`}
                onClick={() => {
                  setInputFocusType('date')
                  setIsShowDateCalendar(!isShowDateCalendar)
                }}
              >
                <span className={styles.searchInputBoxResultDateText}>
                  {formattedDateTime(selectedDate.startDate, locale)}{' '}
                  {selectedDateTime.startDateTime}
                </span>
                <span className={styles.searchInputBoxResultDateText}>-</span>
                <span className={styles.searchInputBoxResultDateText}>
                  {formattedDateTime(selectedDate.endDate, locale)} {selectedDateTime.endDateTime}
                </span>
              </div>
            )}
            <div className={styles.searchInputBoxDateCalendar}>
              <DateRangeCalendarComponent
                locale={locale}
                isShow={isShowDateCalendar}
                isShowTimePicker={true}
                onPressCloseModalRequest={() => {
                  setIsShowDateCalendar(false)
                }}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedDateTime={selectedDateTime}
                setSelectedDateTime={setSelectedDateTime}
              />
            </div>
          </div>
          <div
            className={`${styles.searchInputBox} ${styles.searchInputBoxLuggage} ${
              inputFocusType === 'luggage' ? styles.searchInputBoxFocused : ''
            }`}
            ref={luggageRef}
          >
            <strong className={styles.searchInputBoxLabel}>
              <span className={styles.searchInputBoxLabelText}>
                {t('SPACES.SEARCH.LUGGAGE.PLACEHOLDER')}
              </span>
            </strong>
            {bagSizeStepper > 0 || suitcaseSizeStepper > 0 ? (
              <div
                className={`${styles.searchInputBoxResult} ${styles.searchInputBoxResultLuggage}`}
              >
                <div className={styles.searchInputBoxResultLuggageCounter}>
                  <img
                    className={`${styles.searchInputBoxResultLuggageCounterIcon} ${styles.searchInputBoxResultLuggageCounterIconBag}`}
                    src="/bag_icon_gray.svg"
                    alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
                  />
                  {t('SPACES.SEARCH.LUGGAGE.BAG_SIZE_COUNT_PLACEHOLDER')} {bagSizeStepper}
                </div>
                <div className={styles.searchInputBoxResultLuggageCounter}>
                  <img
                    className={`${styles.searchInputBoxResultLuggageCounterIcon} ${styles.searchInputBoxResultLuggageCounterIconSuitCase}`}
                    src="/luggage_icon.svg"
                    alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
                  />
                  {t('SPACES.SEARCH.LUGGAGE.SUITECASE_SIZE_COUNT_PLACEHOLDER')}{' '}
                  {suitcaseSizeStepper}
                </div>
              </div>
            ) : (
              <span
                className={styles.searchInputBoxPlaceholder}
                onClick={() => {
                  setInputFocusType('luggage')
                  _onPressLuggageContainer
                }}
              >
                {t('SPACES.SEARCH.ENTER_LUGGAGE_COUNT')}
              </span>
            )}
            {openLuggage && (
              <div className={styles.luggageContainer}>
                <div className={styles.luggageContainerBagSize}>
                  <div className={styles.luggageContainerCounter}>
                    <div className={styles.luggageContainerCounterLabel}>
                      {t('COMMON.BAG_SIZE')}
                    </div>
                    <LuggageButtonComponent
                      sizeStepper={bagSizeStepper}
                      setSizeStepper={setBagSizeStepper}
                    />
                  </div>
                  <div className={styles.luggageContainerDescription}>
                    {t('SPACES.SEARCH.LUGGAGE.BAG_SIZE_DESCRIPTION')}
                  </div>
                </div>
                <div className={styles.luggageContainerSuiteCaseSize}>
                  <div className={styles.luggageContainerCounter}>
                    <div className={styles.luggageContainerCounterLabel}>
                      {t('COMMON.SUITECASE_SIZE')}
                    </div>
                    <LuggageButtonComponent
                      sizeStepper={suitcaseSizeStepper}
                      setSizeStepper={setSuitcaseSizeStepper}
                    />
                  </div>
                  <div className={styles.luggageContainerDescription}>
                    {t('SPACES.SEARCH.LUGGAGE.SUITECASE_SIZE_DESCRIPTION')}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`${styles.searchInputBox} ${styles.searchInputBoxDateLuggage}`}
            onClick={() => {
              setIsShowDateLuggageModal(!isShowDateLuggageModal)
            }}
          >
            <strong className={styles.searchInputBoxLabel}>
              <span className={styles.searchInputBoxLabelText}>
                {t('SPACES.SEARCH.AREA.PLACEHOLDER')}
              </span>
              <img
                className={styles.searchInputBoxLabelIcon}
                src="/calendar_icon_navy_dark.svg"
                alt={t('META.PLACEHOLDER_CALENDAR_ICON')}
              />
            </strong>
            {selectedDate.startDate === null && selectedDate.endDate === null ? (
              <span className={styles.searchInputBoxPlaceholder}>
                {t('SPACES.SEARCH.SELECT_CHECK_DATETIME_LUGGAGE')}
              </span>
            ) : (
              <div className={styles.searchInputBoxResult}>
                <div className={styles.searchInputBoxResultDate}>
                  <span className={styles.searchInputBoxResultDateText}>
                    {selectedDate.startDate !== null && selectedDate.startDate.format('MM/DD')}{' '}
                    {selectedDateTime.startDateTime}
                  </span>
                  <span className={styles.searchInputBoxResultDateText}>-</span>
                  <span className={styles.searchInputBoxResultDateText}>
                    {selectedDate.endDate !== null && selectedDate.endDate.format('MM/DD')}{' '}
                    {selectedDateTime.endDateTime}
                  </span>
                </div>
                <div className={styles.searchInputBoxResultLuggage}>
                  <div className={styles.searchInputBoxResultLuggageCounter}>
                    <img
                      className={`${styles.searchInputBoxResultLuggageCounterIcon} ${styles.searchInputBoxResultLuggageCounterIconBag}`}
                      src="/bag_icon_gray.svg"
                      alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
                    />{' '}
                    x {bagSizeStepper}
                  </div>
                  <div className={styles.searchInputBoxResultLuggageCounter}>
                    <img
                      className={`${styles.searchInputBoxResultLuggageCounterIcon} ${styles.searchInputBoxResultLuggageCounterIconSuitCase}`}
                      src="/luggage_icon.svg"
                      alt={t('META.PLACEHOLDER_LUGGAGE_ICON')}
                    />{' '}
                    x {suitcaseSizeStepper}
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className={styles.searchBtn}
            disabled={isSearchButtonActive}
            onClick={() => {
              page === 'top'
                ? onPressNavigationSearchSpacePage(
                    inputText,
                    selectedDate,
                    selectedDateTime.startDateTime,
                    selectedDateTime.endDateTime,
                    bagSizeStepper,
                    suitcaseSizeStepper,
                    latLng.lat,
                    latLng.lon,
                    false
                  )
                : onPressSearchSpace(
                    latLng,
                    selectedDate,
                    selectedDateTime,
                    bagSizeStepper,
                    suitcaseSizeStepper
                  )
            }}
          >
            <img
              src="/search_button_icon_white.svg"
              className={styles.searchBtnImage}
              alt="Search"
            />
            <span className={styles.searchBtnText}>{t('SPACES.SEARCH.SEARCH_PLACE')}</span>
          </button>
        </div>
      </div>
      {isShowAreaModal && (
        <SearchAreaModal
          isShow={isShowAreaModal}
          defaultAreaName={inputText}
          onPressCloseModalRequest={() => {
            setIsShowAreaModal(!isShowAreaModal)
          }}
          onPressApplyButton={(selectedAreaName, selectedLatLng) => {
            console.log('selectedAreaName', selectedAreaName)
            setInputText(selectedAreaName)
            setLatLng(selectedLatLng)
            setIsShowAreaModal(false)

            //ページによって関数の呼び出し変更
            if (page === 'locations') {
              onPressSearchSpace(
                selectedLatLng,
                selectedDate,
                selectedDateTime,
                bagSizeStepper,
                suitcaseSizeStepper
              )
            }
          }}
          hideSearchCurrentPosition={page === 'locations'}
          onPressCurrentPosition={() => {
            onPressNavigationSearchSpacePage(
              inputText,
              selectedDate,
              selectedDateTime.startDateTime,
              selectedDateTime.endDateTime,
              bagSizeStepper,
              suitcaseSizeStepper,
              latLng.lat,
              latLng.lon,
              true
            )
          }}
        />
      )}
      {isShowDateLuggageModal && (
        <SearchDateLuggageModal
          locale={locale}
          isShow={isShowDateLuggageModal}
          onPressCloseModalRequest={() => {
            setIsShowDateLuggageModal(!isShowDateLuggageModal)
          }}
          onPressApplyButton={(
            _selectedDate,
            _selectedDateTime,
            _bagSizeStepper,
            _suitcaseSizeStepper
          ) => {
            applySearchOnParamsDateLuggageModal(
              _selectedDate,
              _selectedDateTime,
              _bagSizeStepper,
              _suitcaseSizeStepper
            )

            setIsShowDateLuggageModal(false)

            //ページによって関数の呼び出し変更
            if (page === 'locations') {
              onPressSearchSpace(
                latLng,
                _selectedDate,
                _selectedDateTime,
                _bagSizeStepper,
                _suitcaseSizeStepper
              )
            }
          }}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedDateTime={selectedDateTime}
          defaultBagSizeStepper={bagSizeStepper}
          defaultSuitcaseSizeStepper={suitcaseSizeStepper}
          isVisibleFilterButton={isVisibleFilterButton}
          onPressSearchFilterModal={onPressSearchFilterModal}
        />
      )}
    </>
  )
}

export default SearchForm
